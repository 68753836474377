import { useActions } from '../../../hooks';
import { useEffect, useState } from 'react';
import { rerunFailedCron } from '../../../api';
import { DEFAULT_API_ERROR } from '../../../constants';
import { CronStatusData, CRON_SUCCESS_MESSAGE } from '../../../state';

// UI COMPONENTS
import DataTable, {
  DataProvider,
  ColumnTemplate,
} from '@publicismedia-ds/ui-datatable';
import Button from '@publicismedia-ds/ui-button';
import Status from '@publicismedia-ds/ui-status';
import Tooltip from '@publicismedia-ds/ui-tooltip';

interface MarinCronsTableProps {
  data: CronStatusData[];
}

function MarinCronsTable({ data }: MarinCronsTableProps) {
  // REDUX ACTIONS
  const { setLoading, alertError, alertSuccess } = useActions();

  // TABLE DATA PROVIDER (SCHEDULED GOALS LIST)
  const [dataProvider, setDataProvider] = useState<any>([]);

  // CREATE TABLE DATA PROVIDER ON INITIAL LOAD AND WHEN DATA CHANGES
  useEffect(() => {
    const marinCrons = data.filter((cron) => cron.platformId === 5);
    setDataProvider(new DataProvider({ data: marinCrons }));
  }, [data]);

  // RERUN FAILED CRON
  const runCronAgain = async (cron: CronStatusData) => {
    setLoading(true);

    try {
      await rerunFailedCron(cron);

      alertSuccess(CRON_SUCCESS_MESSAGE);
    } catch (error: any) {
      alertError(
        error.response?.data?.message ||
          error?.response?.data?.errorMessage ||
          DEFAULT_API_ERROR
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <DataTable
      bindKey="user_id"
      className="sa360-crons-data-table"
      data={dataProvider}
      stickyHead={true}
      emptyState={
        <>
          <h2>No data available.</h2>
        </>
      }
    >
      <ColumnTemplate bind="userName" isSortable>
        User
      </ColumnTemplate>
      <ColumnTemplate bind="agencyName" isSortable>
        Agency
      </ColumnTemplate>
      <ColumnTemplate bind="advertiserName" isSortable>
        Sub Accounts
      </ColumnTemplate>
      <ColumnTemplate bind="reportType" isSortable>
        Report Type
      </ColumnTemplate>
      <ColumnTemplate
        bind="startTime"
        onRender={(data: any) => {
          if (typeof data === 'object') {
            return (
              <span>{`${data?.date?.month}/${data?.date?.day}/${data?.date?.year} ${data?.time?.hour}:${data?.time?.minute}:${data?.time?.second}`}</span>
            );
          }
          return <span>{data}</span>;
        }}
        isSortable
      >
        Start Time
      </ColumnTemplate>
      <ColumnTemplate
        bind="endTime"
        onRender={(data: any) => {
          if (typeof data === 'object') {
            return (
              <span>{`${data?.date?.month}/${data?.date?.day}/${data?.date?.year} ${data?.time?.hour}:${data?.time?.minute}:${data?.time?.second}`}</span>
            );
          }
          return <span>{data}</span>;
        }}
        isSortable
      >
        End Time
      </ColumnTemplate>
      <ColumnTemplate bind="status" isSortable>
        Status
      </ColumnTemplate>
      <ColumnTemplate
        bind="status"
        onRender={(status: string, rowData: CronStatusData) => {
          return (
            <>
              {status === 'Failure' && (
                <Tooltip title="Run Again">
                  <i
                    className="icon-restore"
                    onClick={() => runCronAgain(rowData)}
                  />
                </Tooltip>
              )}
            </>
          );
        }}
      >
        Actions
      </ColumnTemplate>
    </DataTable>
  );
}

export default MarinCronsTable;
