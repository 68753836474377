import produce from 'immer';
import { PLATFORM_IDS } from '../../../constants';
import {
  AccountReportType,
  DropdownOption,
  ExistingAccount,
} from '../../models';
import {
  INITIAL_ADWORDS_CRON_FORM,
  AdwordsCronFormAction,
  AdwordsCronFormState,
  ADWORDS_CRON_ACTION_TYPES,
} from '../form-models';

export const adwordsCronFormReducer = produce(
  (
    state: AdwordsCronFormState,
    action: AdwordsCronFormAction
  ): AdwordsCronFormState => {
    switch (action.type) {
      case ADWORDS_CRON_ACTION_TYPES.LOAD_ACCOUNTS_DATA: {
        // CREATE ACCOUNTS DATA OBJECT
        state.accountsData = action.payload.reduce(
          (data: { [key: string]: ExistingAccount }, account) => {
            // FILTER OUT NON ADWORDS PLATFORM ACCOUNTS
            if (
              account.platform_id.toString() ===
              PLATFORM_IDS.GOOGLE_ADS.toString()
            ) {
              data[account.agency_id] = account;
            }
            return data;
          },
          {}
        );

        return state;
      }

      case ADWORDS_CRON_ACTION_TYPES.SELECT_REPORT_TYPE:
        state.reportType = action.payload;
        state.selectedAgency = '';
        state.selectedSubAccount = '';

        // CREATE AGENCY OPTIONS BASED ON SELECTED REPORT TYPE
        state.agencyOptions = Object.values(state.accountsData).reduce(
          (options: DropdownOption[], account) => {
            if (
              account.reportType.includes(
                action.payload.value as AccountReportType
              )
            ) {
              options.push({
                label: account.account_name,
                value: account.agency_id,
              });
            }
            return options;
          },
          []
        );

        return state;

      case ADWORDS_CRON_ACTION_TYPES.SELECT_AGENCY: {
        const agnecyId = action.payload.value;

        state.selectedAgency = action.payload;
        state.selectedSubAccount = '';

        // CREATE SUB ACCOUNT OPTIONS BASED ON AGNECY
        state.subAccountOptions = state.accountsData[agnecyId]?.brands.reduce(
          (options: DropdownOption[], brand) => {
            if (brand.brandStatus === 1) {
              options.push({
                label: brand.brandName,
                value: brand.advertiserId,
              });
            }
            return options;
          },
          []
        );

        return state;
      }

      case ADWORDS_CRON_ACTION_TYPES.SELECT_SUB_ACCOUNT:
        state.selectedSubAccount = action.payload;
        return state;

      case ADWORDS_CRON_ACTION_TYPES.SELECT_UPLOAD_FILE:
        state.selectedFile = action.payload;
        return state;

      case ADWORDS_CRON_ACTION_TYPES.RESET_ADWORDS_CRON_FORM: {
        state = { ...INITIAL_ADWORDS_CRON_FORM };
        return state;
      }

      default:
        return state;
    }
  },
  INITIAL_ADWORDS_CRON_FORM
);
