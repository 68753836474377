import {
    DropdownOption,
    CUSTOM_REPORTS,
    ExistingAccount,
    adwordsCronFormReducer,
    INITIAL_ADWORDS_CRON_FORM,
    ADWORDS_CRON_ACTION_TYPES,
    ADWORDS_TEMPLATES,
} from '../../state';
import { useActions } from '../../hooks';
import React, { useEffect, useReducer } from 'react';
import { onPreventEnterKeySubmit } from '../../utils';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { downloadReportTemplate } from '../../api';

// COMPONENT PROPS
interface AdwordsCronFormProps {
    data: ExistingAccount[] | null;
    onSubmit: (formState: any) => Promise<void>;
}

function AdwordsCronForm({ data, onSubmit }: AdwordsCronFormProps) {
    // REDUX ACTIONS
    const { setLoading, alertError } = useActions();

    // GOOGLE ADWORDS CRON FORM STATE
    const [state, dispatch] = useReducer(
        adwordsCronFormReducer,
        INITIAL_ADWORDS_CRON_FORM
    );

    // LOAD FORM OPTIONS
    useEffect(() => {
        if (!data) return;
        dispatch({
            type: ADWORDS_CRON_ACTION_TYPES.LOAD_ACCOUNTS_DATA,
            payload: data,
        });
    }, [data]);

    // HANDLE REPORT TYPE SELECTION
    const onSelectReportType = (selected: DropdownOption) => {
        dispatch({
            type: ADWORDS_CRON_ACTION_TYPES.SELECT_REPORT_TYPE,
            payload: selected,
        });
    };

    // HANDLE AGENCY SELECTION
    const onSelectAgency = (selected: DropdownOption) => {
        dispatch({
            type: ADWORDS_CRON_ACTION_TYPES.SELECT_AGENCY,
            payload: selected,
        });
    };

    // HANDLE SUB ACCOUNT SELECTION
    const onSelectSubAccount = (selected: DropdownOption) => {
        dispatch({
            type: ADWORDS_CRON_ACTION_TYPES.SELECT_SUB_ACCOUNT,
            payload: selected,
        });
    };

    // HANDLE SELECT UPLOAD FILE
    const onSelectUploadFile = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const file = evt.target.files?.[0] || '';

        dispatch({
            type: ADWORDS_CRON_ACTION_TYPES.SELECT_UPLOAD_FILE,
            payload: file,
        });
    };

    // HANDLE DOWNLOADING FILE TEMPLATE
    const onDownloadTemplate = async () => {
        // SET TEMPLATE BASED ON REPORT TYPE
        const template =
            state.reportType && state.reportType.value === 'Campaign'
                ? ADWORDS_TEMPLATES.CAMPAIGN
                : ADWORDS_TEMPLATES.KEYWORD;

        setLoading(true);
        try {
            await downloadReportTemplate(template);
        } catch (error: any) {
            alertError(
                error.response?.data?.message ||
                    error.response?.data?.errorMessage ||
                    'Unable to download requested template. Please contact Darwin support.'
            );
        } finally {
            setLoading(false);
        }
    };

    // ENABLE/DISABLE SUBMIT BUTTON
    const isSubmitDisabled =
        !state.reportType ||
        !state.selectedAgency ||
        !state.selectedSubAccount ||
        !state.selectedFile;

    // HANDLE ADWORDS CRON SUBMIT
    const onSubmitAdwordsCron = async (evt: React.FormEvent) => {
        evt.preventDefault();

        await onSubmit(state);
        dispatch({
            type: ADWORDS_CRON_ACTION_TYPES.RESET_ADWORDS_CRON_FORM,
        });
    };

    return (
        <div className="cron-form-container">
            <form
                onSubmit={onSubmitAdwordsCron}
                onKeyUp={onPreventEnterKeySubmit}
            >
                <div className="form-row">
                    <Dropdown
                        className="form-row-item"
                        options={CUSTOM_REPORTS.map((report) => ({
                            label: report,
                            value: report,
                        }))}
                        value={state.reportType}
                        defaultValue={state.reportType}
                        onChange={onSelectReportType}
                        required
                    >
                        Report Type
                    </Dropdown>
                    <Dropdown
                        className="form-row-item"
                        options={state.agencyOptions}
                        value={state.selectedAgency}
                        defaultValue={state.selectedAgency}
                        onChange={onSelectAgency}
                        required
                    >
                        Agency
                    </Dropdown>
                    <Dropdown
                        className="form-row-item"
                        options={state.subAccountOptions}
                        value={state.selectedSubAccount}
                        defaultValue={state.selectedSubAccount}
                        onChange={onSelectSubAccount}
                        required
                    >
                        Sub Account
                    </Dropdown>
                </div>
                <div className="form-row">
                    <div className="upload-file-input">
                        <label>
                            Upload{' '}
                            {state.reportType &&
                            state.reportType.value === 'Campaign'
                                ? 'Campaign'
                                : 'Keyword'}{' '}
                            File:
                        </label>
                        <input
                            type="file"
                            accept=".xlsx"
                            onChange={onSelectUploadFile}
                        />
                        <a onClick={onDownloadTemplate}>
                            Download{' '}
                            {state.reportType &&
                            state.reportType.value === 'Campaign'
                                ? 'Campaign'
                                : 'Keyword'}{' '}
                            Template
                        </a>
                    </div>
                </div>
                <div className="form-row">
                    <ul style={{ color: 'red' }}>
                        <li>Notes:</li>
                        <li>1. Upload file in .xlsx format only.</li>
                        <li>
                            2. Download the template files for Campaign/Keyword
                            from the link provided.
                        </li>
                        <li>
                            3. Make sure the uploaded files have the same format
                            as the template files.
                        </li>
                        <li>
                            4. Do not modify/change the column names or the
                            order of columns specified in the template files.
                        </li>
                        <li>
                            5.Please make sure you specify all the custom
                            columns after the columns mentioned in the template
                            files.
                        </li>
                        <li>
                            6. Once the data is successfully merged, it will be
                            available to the user until the latest data for the
                            next day is pulled.
                        </li>
                    </ul>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '2rem 0',
                    }}
                >
                    <Button type="submit" disabled={isSubmitDisabled}>
                        Submit
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default AdwordsCronForm;
